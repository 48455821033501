import { GAME_NAMES } from '@/helpers';

const pricesOneTimeBigGamesEUR = {
  1: 39,
  3: 79,
  5: 129,
  7: 169,
  9: 209
};

const pricesOneTimeSmallGamesEUR = {
  1: 20,
  3: 39,
  5: 59,
  7: 79,
  9: 99
};

const pricesOneTimeTutorialsEmployeesEUR = {
  10: 249,
  50: 449,
  200: 699,
  1000: 1199,
  1000000: 2999
};
const pricesSubscriptionBigGamesEUR = {
  1: 29,
  3: 59,
  5: 89,
  7: 119,
  9: 149,
  bigCompany: 299,
  unlimited: 599
};

const pricesSubscriptionSmallGamesEUR = {
  1: 15,
  3: 34,
  5: 49,
  7: 59,
  9: 69,
  bigCompany: 149,
  unlimited: 399
};

const pricesSubscriptionTutorialsEmployeesEUR = {
  10: 149,
  50: 349,
  200: 599,
  1000: 999,
  1000000: 1999
};

const oneTimePricingCommon = {
  mode: 'payment',
  currency: 'EUR'
};

export const oneTimePricingFunBusinessGameEURStaging = [
  {
    ...oneTimePricingCommon,
    gamesInPackage: 1,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    price: pricesOneTimeBigGamesEUR[1],
    packageId: 'price_1JJasgL1MegjdZyHxq5V6qrS'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 3,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    price: pricesOneTimeBigGamesEUR[3],
    packageId: 'price_1JJarjL1MegjdZyHl5DHgJcI'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 5,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    price: pricesOneTimeBigGamesEUR[5],
    packageId: 'price_1JJaq6L1MegjdZyHpA1asJ5R'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 7,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    price: pricesOneTimeBigGamesEUR[7],
    packageId: 'price_1JJap0L1MegjdZyHtBm52dHi'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 9,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    price: pricesOneTimeBigGamesEUR[9],
    packageId: 'price_1JJao8L1MegjdZyHmC1Hp90A'
  }
];

export const oneTimePricingFunTeamBiddingEURStaging = [
  {
    ...oneTimePricingCommon,
    gamesInPackage: 1,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    price: pricesOneTimeBigGamesEUR[1],
    packageId: 'price_1JJaseL1MegjdZyHR19bBWUQ'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 3,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    price: pricesOneTimeBigGamesEUR[3],
    packageId: 'price_1JJarhL1MegjdZyHwzvrN3gv'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 5,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    price: pricesOneTimeBigGamesEUR[5],
    packageId: 'price_1JJaq4L1MegjdZyHWBPEIDNK'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 7,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    price: pricesOneTimeBigGamesEUR[7],
    packageId: 'price_1JJaoyL1MegjdZyHmXlK8Kvd'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 9,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    price: pricesOneTimeBigGamesEUR[9],
    packageId: 'price_1JJao6L1MegjdZyHGEAUCCDu'
  }
];

export const oneTimePricingFunIceBreakerEURStaging = [
  {
    ...oneTimePricingCommon,
    gamesInPackage: 1,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    price: pricesOneTimeSmallGamesEUR[1],
    packageId: 'price_1JJasXL1MegjdZyHQjsfHiw1'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 3,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    price: pricesOneTimeSmallGamesEUR[3],
    packageId: 'price_1JJarZL1MegjdZyHT4k0Ofo9'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 5,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    price: pricesOneTimeSmallGamesEUR[5],
    packageId: 'price_1JJaptL1MegjdZyHBuA4scrs'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 7,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    price: pricesOneTimeSmallGamesEUR[7],
    packageId: 'price_1JJaorL1MegjdZyHAXTjtev5'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 9,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    price: pricesOneTimeSmallGamesEUR[9],
    packageId: 'price_1JJanzL1MegjdZyHjQxB2Wyb'
  }
];

const monthlyPricingCommon = {
  mode: 'subscription',
  currency: 'EUR'
};

export const monthlyPricingFunBusinessGameEURStaging = [
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    gamesInPackage: 1,
    price: pricesSubscriptionBigGamesEUR[1],
    packageId: 'price_1JJZqtL1MegjdZyHfbeDVfrG'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    gamesInPackage: 3,
    price: pricesSubscriptionBigGamesEUR[3],
    packageId: 'price_1JJZplL1MegjdZyHEBqajrOY'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    gamesInPackage: 5,
    price: pricesSubscriptionBigGamesEUR[5],
    packageId: 'price_1JJZntL1MegjdZyHRW4nXCfY'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    gamesInPackage: 7,
    price: pricesSubscriptionBigGamesEUR[7],
    packageId: 'price_1JJZXIL1MegjdZyHlojCYygC'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    gamesInPackage: 9,
    price: pricesSubscriptionBigGamesEUR[9],
    packageId: 'price_1JJZWNL1MegjdZyHJRbYxXiM'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    bigCompany: true,
    gamesInPackage: 30,
    price: pricesSubscriptionBigGamesEUR.bigCompany,
    packageId: 'price_1JJZV0L1MegjdZyHIBdw5sfl'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    unlimited: true,
    gamesInPackage: 99999,
    price: pricesSubscriptionBigGamesEUR.unlimited,
    packageId: 'price_1JJZTaL1MegjdZyH8pKqccSp'
  }
];

export const monthlyPricingFunTeamBiddingEURStaging = [
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    gamesInPackage: 1,
    price: pricesSubscriptionBigGamesEUR[1],
    packageId: 'price_1JJZrLL1MegjdZyHJaEsqRgr'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    gamesInPackage: 3,
    price: pricesSubscriptionBigGamesEUR[3],
    packageId: 'price_1JJZpkL1MegjdZyHUOX6TQn2'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    gamesInPackage: 5,
    price: pricesSubscriptionBigGamesEUR[5],
    packageId: 'price_1JJZnyL1MegjdZyHn8mmMad2'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    gamesInPackage: 7,
    price: pricesSubscriptionBigGamesEUR[7],
    packageId: 'price_1JJZXEL1MegjdZyHLpS5tppq'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    gamesInPackage: 9,
    price: pricesSubscriptionBigGamesEUR[9],
    packageId: 'price_1JJZWIL1MegjdZyH1SUvpMey'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    bigCompany: true,
    gamesInPackage: 30,
    price: pricesSubscriptionBigGamesEUR.bigCompany,
    packageId: 'price_1JJZV7L1MegjdZyHskNyMzMw'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    unlimited: true,
    gamesInPackage: 99999,
    price: pricesSubscriptionBigGamesEUR.unlimited,
    packageId: 'price_1JJZTjL1MegjdZyHhYkYugXj'
  }
];

export const monthlyPricingFunIceBreakerEURStaging = [
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    gamesInPackage: 1,
    price: pricesSubscriptionSmallGamesEUR[1],
    packageId: 'price_1JJZrTL1MegjdZyH1b8X2PqY'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    gamesInPackage: 3,
    price: pricesSubscriptionSmallGamesEUR[3],
    packageId: 'price_1JJZpyL1MegjdZyHTnUkJxUo'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    gamesInPackage: 5,
    price: pricesSubscriptionSmallGamesEUR[5],
    packageId: 'price_1JJZo8L1MegjdZyHkvu7emsG'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    gamesInPackage: 7,
    price: pricesSubscriptionSmallGamesEUR[7],
    packageId: 'price_1JJZX5L1MegjdZyHz4NqabhW'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    gamesInPackage: 9,
    price: pricesSubscriptionSmallGamesEUR[9],
    packageId: 'price_1JJZW7L1MegjdZyHPA17K33p'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    gamesInPackage: 30,
    bigCompany: true,
    price: pricesSubscriptionSmallGamesEUR.bigCompany,
    packageId: 'price_1JJZVOL1MegjdZyHfVQwW7VF'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    unlimited: true,
    gamesInPackage: 99999,
    price: pricesSubscriptionSmallGamesEUR.unlimited,
    packageId: 'price_1JJZTAL1MegjdZyHIDOjewhu'
  }
];
