import { GAME_NAMES } from '@/helpers';

// price_1JKbqaL1MegjdZyHuaHPc6ah,1 FunBusinessGame,pln,
// price_1JKbqYL1MegjdZyHltCbUgAP,3 FunBusinessGame,pln,
// price_1JKbqWL1MegjdZyHZtqRkGjf,5 FunBusinessGame,pln,
// price_1JKbqVL1MegjdZyHYET5rCq1,7 FunBusinessGame,pln,
// price_1JKbqUL1MegjdZyHxINM5v3K,9 FunBusinessGame,pln,
// price_1JKbqIL1MegjdZyH8FEiucZh,1 FunTeamBidding,pln,
// price_1JKbqHL1MegjdZyHzZFmM3w9,3 FunTeamBidding,pln,
// price_1JKbqFL1MegjdZyHeS6HAQDm,5 FunTeamBidding,pln,
// price_1JKbqEL1MegjdZyHSRGcR4E6,7 FunTeamBidding,pln,
// price_1JKbqCL1MegjdZyHP4DvTEFP,9 FunTeamBidding,pln,
// price_1JKbq2L1MegjdZyHrvdA23aW,1 FunIceBreaker,pln,
// price_1JKbq1L1MegjdZyH5MfAxrQU,3 FunIceBreaker,pln,
// price_1JKbpzL1MegjdZyHajNgt8lX,5 FunIceBreaker,pln,
// price_1JKbpyL1MegjdZyHGrGaz432,7 FunIceBreaker,pln,
// price_1JKbpwL1MegjdZyHZBujuXNP,9 FunIceBreaker,pln,
//
//
// price_1JKbqbL1MegjdZyHyjxP55v8,1 FunBusinessGame,pln,month
// price_1JKbqYL1MegjdZyH6NPMRI1j,3 FunBusinessGame,pln,month
// price_1JKbqWL1MegjdZyHirIoCbDq,5 FunBusinessGame,pln,month
// price_1JKbqVL1MegjdZyHorzhRqAm,7 FunBusinessGame,pln,month
// price_1JKbqUL1MegjdZyHOZI6ZEcz,9 FunBusinessGame,pln,month
// price_1JKbplL1MegjdZyHIpS2Xt3q,30 FunBusinessGame,pln,month
// price_1JKbpgL1MegjdZyHEuqAZ5bL,Unlimited FunBusinessGame,pln,month
// price_1JKbqIL1MegjdZyHdNr3AaTj,1 FunTeamBidding,pln,month
// price_1JKbqHL1MegjdZyHQuLVq25H,3 FunTeamBidding,pln,month
// price_1JKbqFL1MegjdZyHyx4toyUF,5 FunTeamBidding,pln,month
// price_1JKbqEL1MegjdZyHsKVHMW5D,7 FunTeamBidding,pln,month
// price_1JKbqCL1MegjdZyHLLc3uwIH,9 FunTeamBidding,pln,month
// price_1JKbpjL1MegjdZyHRS0qxZUS,30 FunTeamBidding,pln,month
// price_1JKbpeL1MegjdZyHVDxJi277,Unlimited FunTeamBidding,pln,month
// price_1JKbq2L1MegjdZyHRbzxVFcn,1 FunIceBreaker,pln,month
// price_1JKbq1L1MegjdZyHUlqkZLcK,3 FunIceBreaker,pln,month
// price_1JKbq0L1MegjdZyH5SaPqbUY,5 FunIceBreaker,pln,month
// price_1JKbpyL1MegjdZyH3XTYiPDw,7 FunIceBreaker,pln,month
// price_1JKbpwL1MegjdZyHr1MOJGNs,9 FunIceBreaker,pln,month
// price_1JKbpiL1MegjdZyHUxuLVHPd,30 FunIceBreaker,pln,month
// price_1JJaGoL1MegjdZyHlHbZR1XB,Unlimited FunIceBreaker,pln,month

const pricesOneTimeBigGamesPLN = {
  1: 99,
  3: 259,
  5: 399,
  7: 539,
  9: 649
};

const pricesOneTimeSmallGamesPLN = {
  1: 69,
  3: 159,
  5: 239,
  7: 319,
  9: 399
};

const pricesOneTimeTutorialsEmployeesPLN = {
  10: 499,
  50: 799,
  200: 1299,
  1000: 1799,
  1000000: 3999
};

const pricesSubscriptionBigGamesPLN = {
  1: 89,
  3: 189,
  5: 269,
  7: 339,
  9: 389,
  bigCompany: 799,
  unlimited: 1999
};

const pricesSubscriptionSmallGamesPLN = {
  1: 49,
  3: 99,
  5: 139,
  7: 179,
  9: 209,
  bigCompany: 499,
  unlimited: 999
};

const pricesSubscriptionTutorialsEmployeesPLN = {
  10: 299,
  50: 599,
  200: 999,
  1000: 1499,
  1000000: 2999
};

const oneTimePricingCommon = {
  mode: 'payment',
  currency: 'PLN'
};

export const oneTimePricingFunBusinessGamePLNProd = [
  {
    ...oneTimePricingCommon,
    gamesInPackage: 1,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    price: pricesOneTimeBigGamesPLN[1],
    packageId: 'price_1JKbqaL1MegjdZyHuaHPc6ah'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 3,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    price: pricesOneTimeBigGamesPLN[3],
    packageId: 'price_1JKbqYL1MegjdZyHltCbUgAP'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 5,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    price: pricesOneTimeBigGamesPLN[5],
    packageId: 'price_1JKbqWL1MegjdZyHZtqRkGjf'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 7,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    price: pricesOneTimeBigGamesPLN[7],
    packageId: 'price_1JKbqVL1MegjdZyHYET5rCq1'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 9,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    price: pricesOneTimeBigGamesPLN[9],
    packageId: 'price_1JKbqUL1MegjdZyHxINM5v3K'
  }
];

export const oneTimePricingFunTeamBiddingPLNProd = [
  {
    ...oneTimePricingCommon,
    gamesInPackage: 1,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    price: pricesOneTimeBigGamesPLN[1],
    packageId: 'price_1JKbqIL1MegjdZyH8FEiucZh'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 3,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    price: pricesOneTimeBigGamesPLN[3],
    packageId: 'price_1JKbqHL1MegjdZyHzZFmM3w9'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 5,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    price: pricesOneTimeBigGamesPLN[5],
    packageId: 'price_1JKbqFL1MegjdZyHeS6HAQDm'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 7,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    price: pricesOneTimeBigGamesPLN[7],
    packageId: 'price_1JKbqEL1MegjdZyHSRGcR4E6'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 9,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    price: pricesOneTimeBigGamesPLN[9],
    packageId: 'price_1JKbqCL1MegjdZyHP4DvTEFP'
  }
];

export const oneTimePricingFunIceBreakerPLNProd = [
  {
    ...oneTimePricingCommon,
    gamesInPackage: 1,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    price: pricesOneTimeSmallGamesPLN[1],
    packageId: 'price_1JKbq2L1MegjdZyHrvdA23aW'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 3,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    price: pricesOneTimeSmallGamesPLN[3],
    packageId: 'price_1JKbq1L1MegjdZyH5MfAxrQU'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 5,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    price: pricesOneTimeSmallGamesPLN[5],
    packageId: 'price_1JKbpzL1MegjdZyHajNgt8lX'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 7,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    price: pricesOneTimeSmallGamesPLN[7],
    packageId: 'price_1JKbpyL1MegjdZyHGrGaz432'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 9,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    price: pricesOneTimeSmallGamesPLN[9],
    packageId: 'price_1JKbpwL1MegjdZyHZBujuXNP'
  }
];

const monthlyPricingCommon = {
  mode: 'subscription',
  currency: 'PLN'
};

export const monthlyPricingFunBusinessGamePLNProd = [
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    gamesInPackage: 1,
    price: pricesSubscriptionBigGamesPLN[1],
    packageId: 'price_1JKbqbL1MegjdZyHyjxP55v8'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    gamesInPackage: 3,
    price: pricesSubscriptionBigGamesPLN[3],
    packageId: 'price_1JKbqYL1MegjdZyH6NPMRI1j'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    gamesInPackage: 5,
    price: pricesSubscriptionBigGamesPLN[5],
    packageId: 'price_1JKbqWL1MegjdZyHirIoCbDq'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    gamesInPackage: 7,
    price: pricesSubscriptionBigGamesPLN[7],
    packageId: 'price_1JKbqVL1MegjdZyHorzhRqAm'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    gamesInPackage: 9,
    price: pricesSubscriptionBigGamesPLN[9],
    packageId: 'price_1JKbqUL1MegjdZyHOZI6ZEcz'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    bigCompany: true,
    gamesInPackage: 30,
    price: pricesSubscriptionBigGamesPLN.bigCompany,
    packageId: 'price_1JKbplL1MegjdZyHIpS2Xt3q'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    unlimited: true,
    gamesInPackage: 99999,
    price: pricesSubscriptionBigGamesPLN.unlimited,
    packageId: 'price_1JKbpgL1MegjdZyHEuqAZ5bL'
  }
];

export const monthlyPricingFunTeamBiddingPLNProd = [
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    gamesInPackage: 1,
    price: pricesSubscriptionBigGamesPLN[1],
    packageId: 'price_1JKbqIL1MegjdZyHdNr3AaTj'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    gamesInPackage: 3,
    price: pricesSubscriptionBigGamesPLN[3],
    packageId: 'price_1JKbqHL1MegjdZyHQuLVq25H'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    gamesInPackage: 5,
    price: pricesSubscriptionBigGamesPLN[5],
    packageId: 'price_1JKbqFL1MegjdZyHyx4toyUF'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    gamesInPackage: 7,
    price: pricesSubscriptionBigGamesPLN[7],
    packageId: 'price_1JKbqEL1MegjdZyHsKVHMW5D'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    gamesInPackage: 9,
    price: pricesSubscriptionBigGamesPLN[9],
    packageId: 'price_1JKbqCL1MegjdZyHLLc3uwIH'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    bigCompany: true,
    gamesInPackage: 30,
    price: pricesSubscriptionBigGamesPLN.bigCompany,
    packageId: 'price_1JKbpjL1MegjdZyHRS0qxZUS'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    unlimited: true,
    gamesInPackage: 99999,
    price: pricesSubscriptionBigGamesPLN.unlimited,
    packageId: 'price_1JKbpeL1MegjdZyHVDxJi277'
  }
];

export const monthlyPricingFunIceBreakerPLNProd = [
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    gamesInPackage: 1,
    price: pricesSubscriptionSmallGamesPLN[1],
    packageId: 'price_1JKbq2L1MegjdZyHRbzxVFcn'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    gamesInPackage: 3,
    price: pricesSubscriptionSmallGamesPLN[3],
    packageId: 'price_1JKbq1L1MegjdZyHUlqkZLcK'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    gamesInPackage: 5,
    price: pricesSubscriptionSmallGamesPLN[5],
    packageId: 'price_1JKbq0L1MegjdZyH5SaPqbUY'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    gamesInPackage: 7,
    price: pricesSubscriptionSmallGamesPLN[7],
    packageId: 'price_1JKbpyL1MegjdZyH3XTYiPDw'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    gamesInPackage: 9,
    price: pricesSubscriptionSmallGamesPLN[9],
    packageId: 'price_1JKbpwL1MegjdZyHr1MOJGNs'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    gamesInPackage: 30,
    bigCompany: true,
    price: pricesSubscriptionSmallGamesPLN.bigCompany,
    packageId: 'price_1JKbpiL1MegjdZyHUxuLVHPd'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    unlimited: true,
    gamesInPackage: 99999,
    price: pricesSubscriptionSmallGamesPLN.unlimited,
    packageId: 'price_1JJaGoL1MegjdZyHlHbZR1XB'
  }
];
