
  import { onMounted, computed, ref, Ref } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import firebase from 'firebase';

  import RegisterFormOnly from '@/components/common/RegisterFormOnly.vue';

  import useToast from '@/composables/use-toast';
  import { useI18n } from 'vue-i18n';
  import { defaultAvatars } from '@/constants/avatars';
  import { emptyFunTeamBiddingSettings } from '@/helpers/funteambidding/initials';
  import { emptyFunIceBreakerSettings } from '@/helpers/funicebreaker/initials';
  import { GAME_NAMES_HUMANIZED } from '@/helpers';
  import {
    MONEY_FOR_REFERRING_TO_OTHERS,
    MONEY_FOR_REGISTER_THROUGH_REFERRAL
  } from '@/constants/referrals';
  import { format } from 'date-fns';
  import { enGB, pl } from 'date-fns/locale';
  const db = firebase.firestore();

  export default {
    components: {
      RegisterFormOnly
    },
    setup() {
      const store = useStore();
      const { currentRoute, push } = useRouter();
      const { errorToast, successToast } = useToast();
      const { locale, t } = useI18n();

      const loading: Ref<string> = ref('');
      const checkoutLoading: Ref<boolean> = ref(false);

      const activePackage = computed(() => store.state.company.package);

      const tokenData: Ref = ref(null);
      const referMoneyForBuyer = ref(0);

      const gamesLeft = computed(() => {
        return store.state.company.package?.gamesLeft;
      });

      onMounted(async () => {
        // @ts-ignore
        locale.value = currentRoute.value.query.language;
      });

      const formatDate = (date: any) =>
        format(date.seconds ? date.seconds : new Date(date), 'dd MMM yyyy, HH:mm', {
          locale: locale.value === 'pl' ? pl : enGB
        });

      const getBoardConfig = async (configName: string) => {
        return await db
          .doc(`boardConfigs/${configName}`)
          .get()
          .then(doc => doc.data())
          .catch(e => {
            throw new Error(e);
          });
      };

      const getChanceCards = async (configName: string) => {
        return await db
          .doc(`chanceCardsConfigs/${configName}`)
          .get()
          .then(doc => doc.data())
          .catch(e => {
            throw new Error(e);
          });
      };

      const getFunIceBreakerQuestions = async () => {
        return await db
          .doc('funIceBreakerQuestions/default')
          .get()
          .then(doc => doc.data())
          .catch(e => {
            throw new Error(e);
          });
      };

      const submit = async (registerData: any) => {
        try {
          loading.value = t('checkout.withRegister.downloadingBoard');

          const boardConfig = await getBoardConfig(registerData.boardTemplate);
          const chanceCards = await getChanceCards(registerData.chanceTemplate);
          const funIceBreakerQuestions = await getFunIceBreakerQuestions();

          loading.value = t('checkout.withRegister.creatingUser');

          await firebase
            .auth()
            .createUserWithEmailAndPassword(registerData.email, registerData.password)
            .catch(e => {
              throw new Error(e);
            });

          loading.value = t('checkout.withRegister.creatingCompany');

          const companyId = await db
            .collection('companies')
            .add({
              avatars: defaultAvatars,
              boardConfig: boardConfig?.config,
              brandColor: '#3061AC',
              chanceCards: chanceCards?.cards,
              currency: null,
              customBoardConfigTemplates: [],
              customChanceCardsTemplates: [],
              funIceBreakerSettings: emptyFunIceBreakerSettings,
              funIceBreakerCustomQuestions: funIceBreakerQuestions?.list,
              funTeamBiddingSettings: emptyFunTeamBiddingSettings,
              funTeamBiddingCustomQuestions: [],
              funTeamBiddingPresets: [],
              logo: null,
              signupLanguage: currentRoute.value.query.language,
              name: registerData.companyName,
              package: null,
              referralCode: Math.random().toString(36).substr(2, 10),
              referralCodeUsedDuringRegistration: registerData.referralCode,
              referralData: {
                balance: 0,
                referredTo: [],
                historyOfUsedBalance: []
              },
              historyOfPurchases: [],
              upgradeConfig: boardConfig?.upgradeConfig,
              userEmails: [registerData.email]
            })
            .then(doc => {
              return doc.id;
            })
            .catch(e => {
              errorToast(t('checkout.withRegister.errorAddingDocument'));
              throw new Error(e);
            });

          await db
            .collection(`companies/${companyId}/users`)
            .doc(registerData.email)
            .set({
              email: registerData.email,
              pending: false,
              roles: {
                admin: true,
                gameCreator: true
              }
            })
            .catch(e => {
              throw new Error(e);
            });

          let endsOn = null;

          tokenData.value = {
            email: registerData.email,
            companyId: companyId,
            companyName: registerData.companyName,
            currency: null,
            signupLanguage: currentRoute.value.query.language,
            referralCodeUsedDuringRegistration: registerData.referralCode,
            firstPurchase: true,
            package: {
              packageId: null,
              gamesInPackage: {
                FUN_TEAM_BIDDING: 100,
                FUN_BUSINESS_GAME: 100,
                FUN_ICE_BREAKER: 100
              },
              gamesLeft: {
                FUN_TEAM_BIDDING: 100,
                FUN_BUSINESS_GAME: 100,
                FUN_ICE_BREAKER: 100
              },
              price: null,
              currency: null,
              mode: 'payment',
              unlimited: true,
              boughtOn: Date.now(),
              endsOn
            }
          };

          await handleCheckoutSuccess();
        } catch (e) {
          loading.value = '';
          errorToast(e.message ? e.message : e);
        }
      };

      const updateReferMoney = async () => {
        try {
          referMoneyForBuyer.value = tokenData.value.referralCodeUsedDuringRegistration
            ? // @ts-ignore
              MONEY_FOR_REGISTER_THROUGH_REFERRAL[tokenData.value.currency]
            : 0;
        } catch (e) {
          errorToast(e);
        }
      };

      const updateCompanyPackageAndPurchaseHistory = async () => {
        try {
          // TODO move to backend functions for safety - backend needed - functions
          const company = await db
            .doc(`companies/${tokenData.value.companyId}`)
            .get()
            .then(doc => doc.data());

          let updatedPayload = null;

          if (tokenData.value.firstPurchase) {
            updatedPayload = {
              package: tokenData.value.package,
              referralData: {
                balance: tokenData.value.referralCodeUsedDuringRegistration
                  ? company?.referralData.balance +
                    // @ts-ignore
                    MONEY_FOR_REGISTER_THROUGH_REFERRAL[tokenData.value.currency]
                  : company?.referralData.balance,
                referredTo: [],
                historyOfUsedBalance: []
              },
              historyOfPurchases: [...company?.historyOfPurchases, tokenData.value.package]
            };
          } else {
            // TODO cancel previous subscription
            if (tokenData.value.mode === 'subscription') {
              updatedPayload = {
                package: tokenData.value.package
              };
            } else {
              updatedPayload = {
                package: {
                  ...company?.package,
                  // TODO needs change
                  gamesLeft: company?.package?.gamesLeft + tokenData.value.gamesInPackage
                }
              };
            }
          }

          await db.doc(`companies/${tokenData.value.companyId}`).update(updatedPayload);
        } catch (e) {
          errorToast(e);
        }
      };

      const updateReferrer = async (currency: string) => {
        try {
          // TODO move to backend functions for safety - backend needed - functions
          const referrer = await db
            .collection('companies')
            .where('referralCode', '==', tokenData.value.referralCodeUsedDuringRegistration)
            .get()
            .then((querySnapshot: any) => {
              const resultArray: any = [];

              querySnapshot.forEach((doc: any) => {
                resultArray.push({ id: doc.id, ...doc.data() });
              });

              return resultArray[0];
            });

          if (!referrer) {
            return;
          }

          await db
            .collection('companies')
            .doc(referrer.id)
            .update({
              referralData: {
                balance:
                  referrer.referralData.balance +
                  // @ts-ignore
                  MONEY_FOR_REFERRING_TO_OTHERS[currency],
                referredTo: [
                  ...referrer.referralData.referredTo,
                  {
                    name: tokenData.value.companyName,
                    // TODO fix how it's calculated
                    used: Date.now() + 20 * 1000
                  }
                ],
                historyOfUsedBalance: referrer.referralData.historyOfUsedBalance
              }
            });
        } catch (e) {
          errorToast(e);
        }
      };

      const sendConfirmationEmail = async () => {
        if (store.state.user?.email) {
          await store.dispatch('sendCheckoutSuccessEmail', {
            locale: locale.value,
            toEmail: store.state.user.email,
            netPrice: tokenData.value.package.price,
            grossPrice: (tokenData.value.package.price * 1.23).toFixed(2),
            currency: tokenData.value.package.currency,
            gamesInPackage: tokenData.value.package.gamesInPackage,
            boughtOn: formatDate(tokenData.value.package.boughtOn),
            endsOn: tokenData.value.package.endsOn
              ? formatDate(tokenData.value.package.endsOn)
              : '',
            referralCodeUsedDuringRegistration: tokenData.value.referralCodeUsedDuringRegistration
          });
        }
      };

      const handleCheckoutSuccess = async () => {
        checkoutLoading.value = true;

        await updateReferMoney();
        await updateCompanyPackageAndPurchaseHistory();
        await sendConfirmationEmail();

        if (tokenData.value.referralCodeUsedDuringRegistration && tokenData.value.firstPurchase) {
          await updateReferrer(tokenData.value.currency);
        }

        checkoutLoading.value = false;

        store.commit('handleCheckoutSuccess', {
          package: tokenData.value.package,
          referralData: {
            balance: tokenData.value.referralCodeUsedDuringRegistration
              ? // @ts-ignore
                MONEY_FOR_REFERRING_TO_OTHERS[tokenData.value.currency]
              : 0,
            referredTo: [],
            historyOfUsedBalance: []
          }
        });

        successToast(t('checkout.success.toast'), 8000);
        push('/dashboard');
      };

      return {
        submit,
        activePackage,
        gamesLeft,
        loading,
        GAME_NAMES_HUMANIZED
      };
    }
  };
