<template>
  <div class="base-box" :class="small ? 'p2' : 'p3'">
    <div class="h3 base-box__heading">{{ title }}</div>
    <div :class="title && 'pt3'" class="flex flex-column flex-auto base-box__content">
      <slot />
    </div>
    <div class="base-box__save" v-if="saving">
      <ProgressSpinner stroke-width="3" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BaseBox',
    props: {
      saving: {
        type: Boolean,
        default: false
      },
      small: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: ''
      }
    }
  };
</script>

<style scoped lang="scss">
  .base-box {
    background: white;
    box-shadow: 0 2px 57px rgba(221, 222, 238, 0.33);
    position: relative;
    border-radius: 12px;
    height: 100%;

    &__save {
      position: absolute;
      right: global-vars(ui-default-measure5x);
      top: global-vars(ui-default-measure5x);
    }

    &__heading {
      position: absolute;
      left: global-vars(ui-default-measure3x);
      top: global-vars(ui-default-measure3x);

      @include lg-down() {
        font-size: 16px;
      }
    }

    &__content {
      height: 100%;
      overflow-y: auto;
    }
  }
</style>
