
  import { onMounted, ref, Ref } from 'vue';
  import firebase from 'firebase';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import { format } from 'date-fns';

  import BaseBox from '@/components/common/BaseBox.vue';
  import LogoSpinner from '@/components/common/LogoSpinner.vue';

  import useToast from '@/composables/use-toast';
  import {
    MONEY_FOR_REFERRING_TO_OTHERS,
    MONEY_FOR_REGISTER_THROUGH_REFERRAL
  } from '@/constants/referrals';
  import { GAME_NAMES_HUMANIZED } from '@/helpers';
  import { useI18n } from 'vue-i18n';
  import { enGB, pl } from 'date-fns/locale';

  const db = firebase.firestore();

  export default {
    name: 'CheckoutSuccess',
    components: {
      BaseBox,
      LogoSpinner
    },
    setup() {
      const { currentRoute } = useRouter();
      const store = useStore();
      const { errorToast } = useToast();
      const { locale } = useI18n();

      const referMoneyForBuyer = ref(0);

      const tokenData: Ref = ref(null);
      const loading: Ref<boolean> = ref(false);

      const formatDate = (date: any) =>
        format(date.seconds ? date.seconds : new Date(date), 'dd MMM yyyy, HH:mm', {
          locale: locale.value === 'pl' ? pl : enGB
        });

      onMounted(async () => {
        // @ts-ignore
        locale.value = currentRoute.value.query.language;
        await handleCheckoutSuccess();
      });

      const decodeToken = async () => {
        try {
          tokenData.value = await store.dispatch('decodeToken', currentRoute.value.query.token);
          referMoneyForBuyer.value = tokenData.value.referralCodeUsedDuringRegistration
            ? // @ts-ignore
              MONEY_FOR_REGISTER_THROUGH_REFERRAL[tokenData.value.currency]
            : 0;
        } catch (e) {
          errorToast(e);
        }
      };

      const updateCompanyPackageAndPurchaseHistory = async () => {
        try {
          // TODO move to backend functions for safety - backend needed - functions
          const company = await db
            .doc(`companies/${tokenData.value.companyId}`)
            .get()
            .then(doc => doc.data());

          let updatedPayload = null;

          if (tokenData.value.firstPurchase) {
            updatedPayload = {
              package: tokenData.value.package,
              referralData: {
                balance: tokenData.value.referralCodeUsedDuringRegistration
                  ? company?.referralData.balance +
                    // @ts-ignore
                    MONEY_FOR_REGISTER_THROUGH_REFERRAL[tokenData.value.currency]
                  : company?.referralData.balance,
                referredTo: [],
                historyOfUsedBalance: []
              },
              historyOfPurchases: [...company?.historyOfPurchases, tokenData.value.package]
            };
          } else {
            // TODO cancel previous subscription
            if (tokenData.value.mode === 'subscription') {
              updatedPayload = {
                package: tokenData.value.package
              };
            } else {
              updatedPayload = {
                package: {
                  ...company?.package,
                  // TODO needs change
                  gamesLeft: company?.package?.gamesLeft + tokenData.value.gamesInPackage
                }
              };
            }
          }

          await db.doc(`companies/${tokenData.value.companyId}`).update(updatedPayload);
        } catch (e) {
          errorToast(e);
        }
      };

      const updateReferrer = async (currency: string) => {
        try {
          // TODO move to backend functions for safety - backend needed - functions
          const referrer = await db
            .collection('companies')
            .where('referralCode', '==', tokenData.value.referralCodeUsedDuringRegistration)
            .get()
            .then((querySnapshot: any) => {
              const resultArray: any = [];

              querySnapshot.forEach((doc: any) => {
                resultArray.push({ id: doc.id, ...doc.data() });
              });

              return resultArray[0];
            });

          if (!referrer) {
            return;
          }

          await db
            .collection('companies')
            .doc(referrer.id)
            .update({
              referralData: {
                balance:
                  referrer.referralData.balance +
                  // @ts-ignore
                  MONEY_FOR_REFERRING_TO_OTHERS[currency],
                referredTo: [
                  ...referrer.referralData.referredTo,
                  {
                    name: tokenData.value.companyName,
                    // TODO fix how it's calculated
                    used: Date.now() + 20 * 1000
                  }
                ],
                historyOfUsedBalance: referrer.referralData.historyOfUsedBalance
              }
            });
        } catch (e) {
          errorToast(e);
        }
      };

      const sendConfirmationEmail = async () => {
        if (store.state.user?.email) {
          await store.dispatch('sendCheckoutSuccessEmail', {
            locale: locale.value,
            toEmail: store.state.user.email,
            netPrice: tokenData.value.package.price,
            grossPrice: (tokenData.value.package.price * 1.23).toFixed(2),
            currency: tokenData.value.package.currency,
            gamesInPackage: tokenData.value.package.gamesInPackage,
            boughtOn: formatDate(tokenData.value.package.boughtOn),
            endsOn: tokenData.value.package.endsOn
              ? formatDate(tokenData.value.package.endsOn)
              : '',
            referralCodeUsedDuringRegistration: tokenData.value.referralCodeUsedDuringRegistration
          });
        }
      };

      const handleCheckoutSuccess = async () => {
        if (!currentRoute.value.query.token) {
          return;
        }

        loading.value = true;

        await decodeToken();
        await updateCompanyPackageAndPurchaseHistory();
        await sendConfirmationEmail();

        if (tokenData.value.referralCodeUsedDuringRegistration && tokenData.value.firstPurchase) {
          await updateReferrer(tokenData.value.currency);
        }

        loading.value = false;

        store.commit('handleCheckoutSuccess', {
          package: tokenData.value.package,
          referralData: {
            balance: tokenData.value.referralCodeUsedDuringRegistration
              ? // @ts-ignore
                MONEY_FOR_REFERRING_TO_OTHERS[tokenData.value.currency]
              : 0,
            referredTo: [],
            historyOfUsedBalance: []
          }
        });
      };

      return {
        tokenData,
        formatDate,
        referMoneyForBuyer,
        loading,
        GAME_NAMES_HUMANIZED
      };
    }
  };
