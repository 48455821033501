import { GAME_NAMES } from '@/helpers';

const pricesOneTimeBigGamesPLN = {
  1: 99,
  3: 259,
  5: 399,
  7: 539,
  9: 649
};

const pricesOneTimeSmallGamesPLN = {
  1: 69,
  3: 159,
  5: 239,
  7: 319,
  9: 399
};

const pricesOneTimeTutorialsEmployeesPLN = {
  10: 499,
  50: 799,
  200: 1299,
  1000: 1799,
  1000000: 3999
};

const pricesSubscriptionBigGamesPLN = {
  1: 89,
  3: 189,
  5: 269,
  7: 339,
  9: 389,
  bigCompany: 799,
  unlimited: 1999
};

const pricesSubscriptionSmallGamesPLN = {
  1: 49,
  3: 99,
  5: 139,
  7: 179,
  9: 209,
  bigCompany: 499,
  unlimited: 999
};

const pricesSubscriptionTutorialsEmployeesPLN = {
  10: 299,
  50: 599,
  200: 999,
  1000: 1499,
  1000000: 2999
};

const oneTimePricingCommon = {
  mode: 'payment',
  currency: 'PLN'
};

export const oneTimePricingFunBusinessGamePLNStaging = [
  {
    ...oneTimePricingCommon,
    gamesInPackage: 1,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    price: pricesOneTimeBigGamesPLN[1],
    packageId: 'price_1JJalTL1MegjdZyHYT80kM8P'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 3,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    price: pricesOneTimeBigGamesPLN[3],
    packageId: 'price_1JJakGL1MegjdZyH8xd4cENK'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 5,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    price: pricesOneTimeBigGamesPLN[5],
    packageId: 'price_1JJajRL1MegjdZyHsKX6X5g1'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 7,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    price: pricesOneTimeBigGamesPLN[7],
    packageId: 'price_1JJahFL1MegjdZyHK0KSsNIj'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 9,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    price: pricesOneTimeBigGamesPLN[9],
    packageId: 'price_1JJafUL1MegjdZyHyjO8scXl'
  }
];

export const oneTimePricingFunTeamBiddingPLNStaging = [
  {
    ...oneTimePricingCommon,
    gamesInPackage: 1,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    price: pricesOneTimeBigGamesPLN[1],
    packageId: 'price_1JJalVL1MegjdZyH4ypwJeE1'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 3,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    price: pricesOneTimeBigGamesPLN[3],
    packageId: 'price_1JJakJL1MegjdZyHbPDOhW13'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 5,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    price: pricesOneTimeBigGamesPLN[5],
    packageId: 'price_1JJajTL1MegjdZyH0UPzLbo1'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 7,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    price: pricesOneTimeBigGamesPLN[7],
    packageId: 'price_1JJahJL1MegjdZyHaiQt6pRh'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 9,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    price: pricesOneTimeBigGamesPLN[9],
    packageId: 'price_1JJadrL1MegjdZyHVN4zB1a0'
  }
];

export const oneTimePricingFunIceBreakerPLNStaging = [
  {
    ...oneTimePricingCommon,
    gamesInPackage: 1,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    price: pricesOneTimeSmallGamesPLN[1],
    packageId: 'price_1JJaleL1MegjdZyHdDVggbPA'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 3,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    price: pricesOneTimeSmallGamesPLN[3],
    packageId: 'price_1JJakSL1MegjdZyHBoH37Bt2'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 5,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    price: pricesOneTimeSmallGamesPLN[5],
    packageId: 'price_1JJajdL1MegjdZyHsDQJ1PjM'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 7,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    price: pricesOneTimeSmallGamesPLN[7],
    packageId: 'price_1JJaieL1MegjdZyHUvMVRrwu'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 9,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    price: pricesOneTimeSmallGamesPLN[9],
    packageId: 'price_1JJaejL1MegjdZyHChzw6DWL'
  }
];

const monthlyPricingCommon = {
  mode: 'subscription',
  currency: 'PLN'
};

export const monthlyPricingFunBusinessGamePLNStaging = [
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    gamesInPackage: 1,
    price: pricesSubscriptionBigGamesPLN[1],
    packageId: 'price_1JJOqrL1MegjdZyHOtwCFBO5'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    gamesInPackage: 3,
    price: pricesSubscriptionBigGamesPLN[3],
    packageId: 'price_1JJOsKL1MegjdZyH4UVeSXFo'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    gamesInPackage: 5,
    price: pricesSubscriptionBigGamesPLN[5],
    packageId: 'price_1JJOsiL1MegjdZyHyh57Ge1i'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    gamesInPackage: 7,
    price: pricesSubscriptionBigGamesPLN[7],
    packageId: 'price_1JJOt7L1MegjdZyHKioHoZJk'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    gamesInPackage: 9,
    price: pricesSubscriptionBigGamesPLN[9],
    packageId: 'price_1JJOtWL1MegjdZyHMoY62Nxw'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    bigCompany: true,
    gamesInPackage: 30,
    price: pricesSubscriptionBigGamesPLN.bigCompany,
    packageId: 'price_1JJZOUL1MegjdZyHM7eUxqar'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    unlimited: true,
    gamesInPackage: 99999,
    price: pricesSubscriptionBigGamesPLN.unlimited,
    packageId: 'price_1JJZQWL1MegjdZyHNxHBt1JR'
  }
];

export const monthlyPricingFunTeamBiddingPLNStaging = [
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    gamesInPackage: 1,
    price: pricesSubscriptionBigGamesPLN[1],
    packageId: 'price_1JJOuHL1MegjdZyHvGRKTECz'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    gamesInPackage: 3,
    price: pricesSubscriptionBigGamesPLN[3],
    packageId: 'price_1JJOuSL1MegjdZyHvivHiBeJ'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    gamesInPackage: 5,
    price: pricesSubscriptionBigGamesPLN[5],
    packageId: 'price_1JJOueL1MegjdZyH6k1eLsnA'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    gamesInPackage: 7,
    price: pricesSubscriptionBigGamesPLN[7],
    packageId: 'price_1JJOurL1MegjdZyHcjGhkHNF'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    gamesInPackage: 9,
    price: pricesSubscriptionBigGamesPLN[9],
    packageId: 'price_1JJOv7L1MegjdZyHGEv9qeWKv'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    bigCompany: true,
    gamesInPackage: 30,
    price: pricesSubscriptionBigGamesPLN.bigCompany,
    packageId: 'price_1JJZP0L1MegjdZyHXp9uAQ6Q'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    unlimited: true,
    gamesInPackage: 99999,
    price: pricesSubscriptionBigGamesPLN.unlimited,
    packageId: 'price_1JJZQuL1MegjdZyHHNKLboG9'
  }
];

export const monthlyPricingFunIceBreakerPLNStaging = [
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    gamesInPackage: 1,
    price: pricesSubscriptionSmallGamesPLN[1],
    packageId: 'price_1JJOw3L1MegjdZyHPVf4CoTa'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    gamesInPackage: 3,
    price: pricesSubscriptionSmallGamesPLN[3],
    packageId: 'price_1JJOwZL1MegjdZyHVBuB6Vpl'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    gamesInPackage: 5,
    price: pricesSubscriptionSmallGamesPLN[5],
    packageId: 'price_1JJOwmL1MegjdZyHMFldfLd8'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    gamesInPackage: 7,
    price: pricesSubscriptionSmallGamesPLN[7],
    packageId: 'price_1JJOwvL1MegjdZyHGBjyp7Nu'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    gamesInPackage: 9,
    price: pricesSubscriptionSmallGamesPLN[9],
    packageId: 'price_1JJOx8L1MegjdZyHjJaqWqxe'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    gamesInPackage: 30,
    bigCompany: true,
    price: pricesSubscriptionSmallGamesPLN.bigCompany,
    packageId: 'price_1JJZPNL1MegjdZyHqkwjXa1B'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    unlimited: true,
    gamesInPackage: 99999,
    price: pricesSubscriptionSmallGamesPLN.unlimited,
    packageId: 'price_1JJZR9L1MegjdZyHxaQvOCit'
  }
];
