import { createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "checkout__content-wrapper" }
const _hoisted_2 = { class: "checkout__content flex flex-wrap clearfix" }
const _hoisted_3 = { class: "col-12 lg-col-6 flex flex-column items-center justify-center checkout__pricing" }
const _hoisted_4 = { class: "h1 mb2" }
const _hoisted_5 = { class: "color-white" }
const _hoisted_6 = { class: "p3 my2 checkout__packages" }
const _hoisted_7 = { class: "color-white" }
const _hoisted_8 = { class: "color-white" }
const _hoisted_9 = { class: "col-12 lg-col-6 flex flex-column items-center checkout__register" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RegisterFormOnly = _resolveComponent("RegisterFormOnly")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("img", {
        src: require(`@/assets/logos/remote-fun/logo-white-text-white.svg`),
        alt: "logo",
        class: "checkout__content-logo mb1"
      }, null, 8, ["src"]),
      _createVNode("div", _hoisted_3, [
        _createVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('checkout.withRegister.youAreBuying')), 1),
        _createVNode("p", _hoisted_5, _toDisplayString($setup.packages && $setup.packages[0] && $setup.packages[0].mode === 'subscription'
              ? _ctx.$t('checkout.withRegister.subscription')
              : _ctx.$t('checkout.withRegister.oneTime')), 1),
        _createVNode("div", _hoisted_6, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList($setup.packages, (item) => {
            return (_openBlock(), _createBlock("div", {
              class: "checkout__pricing-item my1",
              key: item.name
            }, [
              _createVNode("b", null, _toDisplayString(item.gamesInPackage) + " x", 1),
              _createTextVNode(" " + _toDisplayString($setup.GAME_NAMES_HUMANIZED[item.name]), 1)
            ]))
          }), 128))
        ]),
        _createVNode("div", _hoisted_7, [
          _createTextVNode(_toDisplayString(_ctx.$t('checkout.price')) + " ", 1),
          _createVNode("b", null, _toDisplayString($setup.packagePrice) + " " + _toDisplayString($setup.packages[0].currency), 1)
        ]),
        _createVNode("div", _hoisted_8, [
          _createTextVNode(_toDisplayString(_ctx.$t('checkout.priceGross')) + " ", 1),
          _createVNode("b", null, _toDisplayString(($setup.packagePrice * 1.23).toFixed(2)) + " " + _toDisplayString($setup.packages[0].currency), 1)
        ])
      ]),
      _createVNode("div", _hoisted_9, [
        _createVNode(_component_RegisterFormOnly, {
          onSubmit: $setup.submit,
          "loading-text": $setup.loading || _ctx.$t('checkout.withRegister.payAndRegister')
        }, null, 8, ["onSubmit", "loading-text"])
      ])
    ])
  ]))
}