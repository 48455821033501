import { GAME_NAMES } from '@/helpers';

// price_1JKbqaL1MegjdZyH2i3Phn8Y,1 FunBusinessGame,eur,
// price_1JKbqYL1MegjdZyHwKiRHcNC,3 FunBusinessGame,eur,
// price_1JKbqWL1MegjdZyHYl3VX52P,5 FunBusinessGame,eur,
// price_1JKbqVL1MegjdZyHbhdAEWrZ,7 FunBusinessGame,eur,
// price_1JKbqUL1MegjdZyHCYX0YZ6S,9 FunBusinessGame,eur,
// price_1JKbqIL1MegjdZyHhblVKjLC,1 FunTeamBidding,eur,
// price_1JKbqHL1MegjdZyHi87QBGPM,3 FunTeamBidding,eur,
// price_1JKbqFL1MegjdZyH902JtyXF,5 FunTeamBidding,eur,
// price_1JKbqEL1MegjdZyHMFZYD1iC,7 FunTeamBidding,eur,
// price_1JKbqCL1MegjdZyHgNhAHSkg,9 FunTeamBidding,eur,
// price_1JKbq2L1MegjdZyHSuFOUBUq,1 FunIceBreaker,eur,
// price_1JKbq1L1MegjdZyHINQtrPeH,3 FunIceBreaker,eur,
// price_1JKbpzL1MegjdZyHT0Cbkmhh,5 FunIceBreaker,eur,
// price_1JKbpyL1MegjdZyHee1DDIQ8,7 FunIceBreaker,eur,
// price_1JKbpwL1MegjdZyHJ3GH5wvw,9 FunIceBreaker,eur,
//
// price_1JKbqaL1MegjdZyHokSQ7wEz,1 FunBusinessGame,eur,month
// price_1JKbqYL1MegjdZyHx4kz4NDX,3 FunBusinessGame,eur,month
// price_1JKbqWL1MegjdZyHUTh36i1F,5 FunBusinessGame,eur,month
// price_1JKbqVL1MegjdZyH2GxS6SyC,7 FunBusinessGame,eur,month
// price_1JKbqUL1MegjdZyH9Xzm8sNg,9 FunBusinessGame,eur,month
// price_1JKbqIL1MegjdZyHGqXtT2yv,1 FunTeamBidding,eur,month
// price_1JKbqHL1MegjdZyHIwiziOl3,3 FunTeamBidding,eur,month
// price_1JKbqFL1MegjdZyH9EDGQfXT,5 FunTeamBidding,eur,month
// price_1JKbqEL1MegjdZyHV49V5IYY,7 FunTeamBidding,eur,month
// price_1JKbqCL1MegjdZyHDto1ZTHH,9 FunTeamBidding,eur,month
// price_1JKbq2L1MegjdZyHnCXwmngx,1 FunIceBreaker,eur,month
// price_1JKbq1L1MegjdZyHBE7MB9EW,3 FunIceBreaker,eur,month
// price_1JKbpzL1MegjdZyHJLKJLI5o,5 FunIceBreaker,eur,month
// price_1JKbpyL1MegjdZyHNn3bZxvb,7 FunIceBreaker,eur,month
// price_1JKbpwL1MegjdZyHZgVyGIfw,9 FunIceBreaker,eur,month
// price_1JKbplL1MegjdZyH292fTNK3,30 FunBusinessGame,eur,month
// price_1JKbpjL1MegjdZyHELt2r8Sj,30 FunTeamBidding,eur,month
// price_1JKbpiL1MegjdZyHBlHiPGcQ,30 FunIceBreaker,eur,month
// price_1JKbpgL1MegjdZyHKMaV2zED,Unlimited FunBusinessGame,eur,month
// price_1JKbpeL1MegjdZyHql3XFIzg,Unlimited FunTeamBidding,eur,month
// price_1JJaGoL1MegjdZyHR07R2UfK,Unlimited FunIceBreaker,eur,month

const pricesOneTimeBigGamesEUR = {
  1: 39,
  3: 79,
  5: 129,
  7: 169,
  9: 209
};

const pricesOneTimeSmallGamesEUR = {
  1: 20,
  3: 39,
  5: 59,
  7: 79,
  9: 99
};

const pricesOneTimeTutorialsEmployeesEUR = {
  10: 249,
  50: 449,
  200: 699,
  1000: 1199,
  1000000: 2999
};
const pricesSubscriptionBigGamesEUR = {
  1: 29,
  3: 59,
  5: 89,
  7: 119,
  9: 149,
  bigCompany: 299,
  unlimited: 599
};

const pricesSubscriptionSmallGamesEUR = {
  1: 15,
  3: 34,
  5: 49,
  7: 59,
  9: 69,
  bigCompany: 149,
  unlimited: 399
};

const pricesSubscriptionTutorialsEmployeesEUR = {
  10: 149,
  50: 349,
  200: 599,
  1000: 999,
  1000000: 1999
};

const oneTimePricingCommon = {
  mode: 'payment',
  currency: 'EUR'
};

export const oneTimePricingFunBusinessGameEURProd = [
  {
    ...oneTimePricingCommon,
    gamesInPackage: 1,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    price: pricesOneTimeBigGamesEUR[1],
    packageId: 'price_1JKbqaL1MegjdZyH2i3Phn8Y'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 3,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    price: pricesOneTimeBigGamesEUR[3],
    packageId: 'price_1JKbqYL1MegjdZyHwKiRHcNC'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 5,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    price: pricesOneTimeBigGamesEUR[5],
    packageId: 'price_1JKbqWL1MegjdZyHYl3VX52P'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 7,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    price: pricesOneTimeBigGamesEUR[7],
    packageId: 'price_1JKbqVL1MegjdZyHbhdAEWrZ'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 9,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    price: pricesOneTimeBigGamesEUR[9],
    packageId: 'price_1JKbqUL1MegjdZyHCYX0YZ6S'
  }
];

export const oneTimePricingFunTeamBiddingEURProd = [
  {
    ...oneTimePricingCommon,
    gamesInPackage: 1,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    price: pricesOneTimeBigGamesEUR[1],
    packageId: 'price_1JKbqIL1MegjdZyHhblVKjLC'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 3,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    price: pricesOneTimeBigGamesEUR[3],
    packageId: 'price_1JKbqHL1MegjdZyHi87QBGPM'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 5,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    price: pricesOneTimeBigGamesEUR[5],
    packageId: 'price_1JKbqFL1MegjdZyH902JtyXF'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 7,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    price: pricesOneTimeBigGamesEUR[7],
    packageId: 'price_1JKbqEL1MegjdZyHMFZYD1iC'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 9,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    price: pricesOneTimeBigGamesEUR[9],
    packageId: 'price_1JKbqCL1MegjdZyHgNhAHSkg'
  }
];

export const oneTimePricingFunIceBreakerEURProd = [
  {
    ...oneTimePricingCommon,
    gamesInPackage: 1,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    price: pricesOneTimeSmallGamesEUR[1],
    packageId: 'price_1JKbq2L1MegjdZyHSuFOUBUq'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 3,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    price: pricesOneTimeSmallGamesEUR[3],
    packageId: 'price_1JKbq1L1MegjdZyHINQtrPeH'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 5,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    price: pricesOneTimeSmallGamesEUR[5],
    packageId: 'price_1JKbpzL1MegjdZyHT0Cbkmhh'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 7,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    price: pricesOneTimeSmallGamesEUR[7],
    packageId: 'price_1JKbpyL1MegjdZyHee1DDIQ8'
  },
  {
    ...oneTimePricingCommon,
    gamesInPackage: 9,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    price: pricesOneTimeSmallGamesEUR[9],
    packageId: 'price_1JKbpwL1MegjdZyHJ3GH5wvw'
  }
];

const monthlyPricingCommon = {
  mode: 'subscription',
  currency: 'EUR'
};

export const monthlyPricingFunBusinessGameEURProd = [
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    gamesInPackage: 1,
    price: pricesSubscriptionBigGamesEUR[1],
    packageId: 'price_1JKbqaL1MegjdZyHokSQ7wEz'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    gamesInPackage: 3,
    price: pricesSubscriptionBigGamesEUR[3],
    packageId: 'price_1JKbqYL1MegjdZyHx4kz4NDX'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    gamesInPackage: 5,
    price: pricesSubscriptionBigGamesEUR[5],
    packageId: 'price_1JKbqWL1MegjdZyHUTh36i1F'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    gamesInPackage: 7,
    price: pricesSubscriptionBigGamesEUR[7],
    packageId: 'price_1JKbqVL1MegjdZyH2GxS6SyC'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    gamesInPackage: 9,
    price: pricesSubscriptionBigGamesEUR[9],
    packageId: 'price_1JKbqUL1MegjdZyH9Xzm8sNg'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    bigCompany: true,
    gamesInPackage: 30,
    price: pricesSubscriptionBigGamesEUR.bigCompany,
    packageId: 'price_1JKbplL1MegjdZyH292fTNK3'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_BUSINESS_GAME,
    unlimited: true,
    gamesInPackage: 99999,
    price: pricesSubscriptionBigGamesEUR.unlimited,
    packageId: 'price_1JKbpgL1MegjdZyHKMaV2zED'
  }
];

export const monthlyPricingFunTeamBiddingEURProd = [
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    gamesInPackage: 1,
    price: pricesSubscriptionBigGamesEUR[1],
    packageId: 'price_1JKbqIL1MegjdZyHGqXtT2yv'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    gamesInPackage: 3,
    price: pricesSubscriptionBigGamesEUR[3],
    packageId: 'price_1JKbqHL1MegjdZyHIwiziOl3'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    gamesInPackage: 5,
    price: pricesSubscriptionBigGamesEUR[5],
    packageId: 'price_1JKbqFL1MegjdZyH9EDGQfXT'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    gamesInPackage: 7,
    price: pricesSubscriptionBigGamesEUR[7],
    packageId: 'price_1JKbqEL1MegjdZyHV49V5IYY'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    gamesInPackage: 9,
    price: pricesSubscriptionBigGamesEUR[9],
    packageId: 'price_1JKbqCL1MegjdZyHDto1ZTHH'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    bigCompany: true,
    gamesInPackage: 30,
    price: pricesSubscriptionBigGamesEUR.bigCompany,
    packageId: 'price_1JKbpjL1MegjdZyHELt2r8Sj'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_TEAM_BIDDING,
    unlimited: true,
    gamesInPackage: 99999,
    price: pricesSubscriptionBigGamesEUR.unlimited,
    packageId: 'price_1JKbpeL1MegjdZyHql3XFIzg'
  }
];

export const monthlyPricingFunIceBreakerEURProd = [
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    gamesInPackage: 1,
    price: pricesSubscriptionSmallGamesEUR[1],
    packageId: 'price_1JKbq2L1MegjdZyHnCXwmngx'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    gamesInPackage: 3,
    price: pricesSubscriptionSmallGamesEUR[3],
    packageId: 'price_1JKbq1L1MegjdZyHBE7MB9EW'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    gamesInPackage: 5,
    price: pricesSubscriptionSmallGamesEUR[5],
    packageId: 'price_1JKbpzL1MegjdZyHJLKJLI5o'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    gamesInPackage: 7,
    price: pricesSubscriptionSmallGamesEUR[7],
    packageId: 'price_1JKbpyL1MegjdZyHNn3bZxvb'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    gamesInPackage: 9,
    price: pricesSubscriptionSmallGamesEUR[9],
    packageId: 'price_1JKbpwL1MegjdZyHZgVyGIfw'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    gamesInPackage: 30,
    bigCompany: true,
    price: pricesSubscriptionSmallGamesEUR.bigCompany,
    packageId: 'price_1JKbpiL1MegjdZyHBlHiPGcQ'
  },
  {
    ...monthlyPricingCommon,
    name: GAME_NAMES.FUN_ICE_BREAKER,
    unlimited: true,
    gamesInPackage: 99999,
    price: pricesSubscriptionSmallGamesEUR.unlimited,
    packageId: 'price_1JJaGoL1MegjdZyHR07R2UfK'
  }
];
