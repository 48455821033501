
  import { reactive, onMounted, computed, ref, Ref } from 'vue';
  import { loadStripe, Stripe } from '@stripe/stripe-js';
  import { addMonths } from 'date-fns';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import firebase from 'firebase';

  import RegisterFormOnly from '@/components/common/RegisterFormOnly.vue';

  import {
    oneTimePricingFunBusinessGamePLNStaging,
    oneTimePricingFunTeamBiddingPLNStaging,
    oneTimePricingFunIceBreakerPLNStaging,
    monthlyPricingFunBusinessGamePLNStaging,
    monthlyPricingFunTeamBiddingPLNStaging,
    monthlyPricingFunIceBreakerPLNStaging
  } from '@/components/common/pricingPLN-staging.constant';
  import {
    oneTimePricingFunBusinessGameEURStaging,
    oneTimePricingFunTeamBiddingEURStaging,
    oneTimePricingFunIceBreakerEURStaging,
    monthlyPricingFunBusinessGameEURStaging,
    monthlyPricingFunTeamBiddingEURStaging,
    monthlyPricingFunIceBreakerEURStaging
  } from '@/components/common/pricingEUR-staging.constant';
  import {
    oneTimePricingFunBusinessGamePLNProd,
    oneTimePricingFunTeamBiddingPLNProd,
    oneTimePricingFunIceBreakerPLNProd,
    monthlyPricingFunBusinessGamePLNProd,
    monthlyPricingFunTeamBiddingPLNProd,
    monthlyPricingFunIceBreakerPLNProd
  } from '@/components/common/pricingPLN-prod.constant';
  import {
    oneTimePricingFunBusinessGameEURProd,
    oneTimePricingFunTeamBiddingEURProd,
    oneTimePricingFunIceBreakerEURProd,
    monthlyPricingFunBusinessGameEURProd,
    monthlyPricingFunTeamBiddingEURProd,
    monthlyPricingFunIceBreakerEURProd
  } from '@/components/common/pricingEUR-prod.constant';

  import useToast from '@/composables/use-toast';
  import { useI18n } from 'vue-i18n';
  import { defaultAvatars } from '@/constants/avatars';
  import { emptyFunTeamBiddingSettings } from '@/helpers/funteambidding/initials';
  import { emptyFunIceBreakerSettings } from '@/helpers/funicebreaker/initials';
  import { GAME_NAMES_HUMANIZED } from '@/helpers';
  const db = firebase.firestore();

  export default {
    components: {
      RegisterFormOnly
    },
    setup() {
      const store = useStore();
      const { currentRoute } = useRouter();
      const { errorToast } = useToast();
      const { locale, t } = useI18n();

      const loading: Ref<string> = ref('');

      const state: { stripe: null | Stripe } = reactive({
        stripe: null
      });

      const packages: any = computed(() => {
        if (!currentRoute.value.query.packageId) {
          return [];
        }

        if (process.env.NODE_ENV === 'development') {
          return [
            ...oneTimePricingFunBusinessGamePLNStaging,
            ...oneTimePricingFunTeamBiddingPLNStaging,
            ...oneTimePricingFunIceBreakerPLNStaging,
            ...monthlyPricingFunBusinessGamePLNStaging,
            ...monthlyPricingFunTeamBiddingPLNStaging,
            ...monthlyPricingFunIceBreakerPLNStaging,
            ...oneTimePricingFunBusinessGameEURStaging,
            ...oneTimePricingFunTeamBiddingEURStaging,
            ...oneTimePricingFunIceBreakerEURStaging,
            ...monthlyPricingFunBusinessGameEURStaging,
            ...monthlyPricingFunTeamBiddingEURStaging,
            ...monthlyPricingFunIceBreakerEURStaging
          ].filter(p => {
            return currentRoute.value.query.packageId?.includes(p.packageId);
          });
        } else {
          return [
            ...oneTimePricingFunBusinessGamePLNProd,
            ...oneTimePricingFunTeamBiddingPLNProd,
            ...oneTimePricingFunIceBreakerPLNProd,
            ...monthlyPricingFunBusinessGamePLNProd,
            ...monthlyPricingFunTeamBiddingPLNProd,
            ...monthlyPricingFunIceBreakerPLNProd,
            ...oneTimePricingFunBusinessGameEURProd,
            ...oneTimePricingFunTeamBiddingEURProd,
            ...oneTimePricingFunIceBreakerEURProd,
            ...monthlyPricingFunBusinessGameEURProd,
            ...monthlyPricingFunTeamBiddingEURProd,
            ...monthlyPricingFunIceBreakerEURProd
          ].filter(p => {
            return currentRoute.value.query.packageId?.includes(p.packageId);
          });
        }
      });

      const packagePrice = computed(() => {
        return packages.value.reduce((acc: number, next: any) => next.price + acc, 0);
      });

      const activePackage = computed(() => {
        return store.state.company.package;
      });

      const gamesLeft = computed(() => {
        return store.state.company.package?.gamesLeft;
      });

      onMounted(async () => {
        // @ts-ignore
        locale.value = currentRoute.value.query.language;
        state.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PK);
      });

      const getBoardConfig = async (configName: string) => {
        return await db
          .doc(`boardConfigs/${configName}`)
          .get()
          .then(doc => doc.data())
          .catch(e => {
            throw new Error(e);
          });
      };

      const getChanceCards = async (configName: string) => {
        return await db
          .doc(`chanceCardsConfigs/${configName}`)
          .get()
          .then(doc => doc.data())
          .catch(e => {
            throw new Error(e);
          });
      };

      const getFunIceBreakerQuestions = async () => {
        return await db
          .doc('funIceBreakerQuestions/default')
          .get()
          .then(doc => doc.data())
          .catch(e => {
            throw new Error(e);
          });
      };

      const submit = async (registerData: any) => {
        try {
          loading.value = t('checkout.withRegister.downloadingBoard');

          const boardConfig = await getBoardConfig(registerData.boardTemplate);
          const chanceCards = await getChanceCards(registerData.chanceTemplate);
          const funIceBreakerQuestions = await getFunIceBreakerQuestions();

          loading.value = t('checkout.withRegister.creatingUser');

          await firebase
            .auth()
            .createUserWithEmailAndPassword(registerData.email, registerData.password)
            .catch(e => {
              throw new Error(e);
            });

          loading.value = t('checkout.withRegister.creatingCompany');

          const companyId = await db
            .collection('companies')
            .add({
              avatars: defaultAvatars,
              boardConfig: boardConfig?.config,
              brandColor: '#3061AC',
              chanceCards: chanceCards?.cards,
              currency: packages.value[0].currency,
              customBoardConfigTemplates: [],
              customChanceCardsTemplates: [],
              funIceBreakerSettings: emptyFunIceBreakerSettings,
              funIceBreakerCustomQuestions: funIceBreakerQuestions?.list,
              funTeamBiddingSettings: emptyFunTeamBiddingSettings,
              funTeamBiddingCustomQuestions: [],
              funTeamBiddingPresets: [],
              logo: null,
              signupLanguage: currentRoute.value.query.language,
              name: registerData.companyName,
              package: null,
              referralCode: Math.random().toString(36).substr(2, 10),
              referralCodeUsedDuringRegistration: registerData.referralCode,
              referralData: {
                balance: 0,
                referredTo: [],
                historyOfUsedBalance: []
              },
              historyOfPurchases: [],
              upgradeConfig: boardConfig?.upgradeConfig,
              userEmails: [registerData.email]
            })
            .then(doc => {
              return doc.id;
            })
            .catch(e => {
              errorToast(t('checkout.withRegister.errorAddingDocument'));
              throw new Error(e);
            });

          await db
            .collection(`companies/${companyId}/users`)
            .doc(registerData.email)
            .set({
              email: registerData.email,
              pending: false,
              roles: {
                admin: true,
                gameCreator: true
              }
            })
            .catch(e => {
              throw new Error(e);
            });

          let endsOn = null;

          if (packages.value[0].mode === 'subscription') {
            endsOn = addMonths(Date.now(), 1);
          }

          const tokenPayload = {
            email: registerData.email,
            companyId: companyId,
            companyName: registerData.companyName,
            currency: packages.value[0].currency,
            signupLanguage: currentRoute.value.query.language,
            referralCodeUsedDuringRegistration: registerData.referralCode,
            firstPurchase: true,
            package: {
              packageId: currentRoute.value.query.packageId,
              gamesInPackage: packages.value.reduce((acc: any, p: any) => {
                acc[p.name] = p.gamesInPackage;
                return acc;
              }, {}),
              gamesLeft: packages.value.reduce((acc: any, p: any) => {
                acc[p.name] = p.gamesInPackage;
                return acc;
              }, {}),
              price: packages.value.reduce((acc: any, p: any) => {
                acc += p.price;
                return acc;
              }, 0),
              currency: packages.value[0].currency,
              mode: packages.value[0].mode,
              unlimited: packages.value[0].unlimited,
              boughtOn: Date.now(),
              endsOn
            }
          };

          const stripePayload = {
            lineItems: packages.value.map((p: any) => ({
              price: p.packageId,
              quantity: 1
            })),
            gamesInPackage: packages.value.reduce((acc: any, p: any) => {
              acc[p.name] = p.gamesInPackage;
              return acc;
            }, {}),
            mode: packages.value[0].mode,
            customerEmail: registerData.email,
            companyName: registerData.companyName,
            invoiceData: registerData.invoiceData
          };

          loading.value = t('checkout.withRegister.redirectingToPayment');

          const sessionId = await createStripeSession(
            stripePayload,
            tokenPayload,
            packages.value[0].mode
          );

          state.stripe?.redirectToCheckout({ sessionId });
        } catch (e) {
          loading.value = '';
          errorToast(e.message ? e.message : e);
        }
      };

      const createStripeSession = async (stripePayload: any, tokenPayload: any, mode: string) => {
        return await store
          .dispatch('createStripeSession', {
            mode,
            stripePayload,
            tokenPayload
          })
          .then(async sessionId => {
            return sessionId;
          })
          .catch(e => {
            errorToast(e.message);
          });
      };

      return {
        submit,
        activePackage,
        gamesLeft,
        loading,
        packages,
        GAME_NAMES_HUMANIZED,
        packagePrice
      };
    }
  };
